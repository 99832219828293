<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5">User Details</h4>
      <vs-form>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <div class="w-full mr-5" align="right">
            <vs-button
              :disabled="!validateForm"
              @click="saveDetails"
              class="m-2"
              >Save Details</vs-button
            >
          </div>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.firstName') && pages.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="pages.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.lastName') && pages.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="pages.lastName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('email') && pages.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('email')"
                v-model="pages.email"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                @click="resetPasswordHandler"
                type="border"
                icon-pack="feather"
                icon="icon-key"
                class="mt-5"
                >Change Password</vs-button
              >
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          vs-mt="6"
        ></vs-row>
      </vs-form>
    </vs-card>
    <div id="resetPasswordContainer">
      <vs-card v-if="resetPassword == true">
        <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
        <form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  type="password"
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 8,
                    regex:
                      /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  icon-no-border
                  icon="icon icon-lock"
                  icon-pack="feather"
                  v-model="password"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  type="password"
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                    confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  icon-no-border
                  icon="icon icon-lock"
                  icon-pack="feather"
                  v-model="confirmPassword"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </form>
      </vs-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";
const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    ahpraRegistrationNumber: {
      required: "Please enter ahpra registration number",
    },
    indemnityInsuranceProvider: {
      required: "Please enter indemnity insurance provider",
    },
    indemnityInsuranceNumber: {
      required: "Please enter indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter indemnity insurance expiry",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The confirmation password do not match",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "adminEditDetail",
  components: {
    Datepicker,
  },
  data: () => ({
    disabledDates: { to: new Date() },
    statusChangedClick: false,
    password: "",
    confirmPassword: "",
    pages: {
      firstName: "",
      lastName: "",
      email: "",
      status: "",
      emailVerifiedAt: null,
      userType: "",
      role: "",
      isMedicalPerson: "",
      ahpraCertificate: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      insuranceCertificate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
    },
    showAphraError: false,
    resetPassword: false,
    switch2: "",
    popUpDelete: false,
    roles: [],
  }),
  computed: {
    validateForm() {
      if (this.pages.userType === "admin") {
        if (this.pages.isMedicalPerson === "yes")
          return (
            !this.errors.any() &&
            this.pages.firstName != "" &&
            this.pages.lastName != "" &&
            this.pages.email != "" &&
            this.pages.password != "" &&
            this.pages.confirmPassword != "" &&
            this.pages.role != "" &&
            this.pages.role != null &&
            this.pages.ahpraRegistrationNumber != "" &&
            this.pages.ahpraRegistrationNumber != null &&
            this.pages.ahpraCertificate != "" &&
            this.pages.ahpraCertificate != null &&
            this.pages.ahpraExiryDate != "" &&
            this.pages.ahpraExiryDate != null &&
            this.pages.insuranceCertificate != "" &&
            this.pages.insuranceCertificate != null &&
            this.pages.indemnityInsuranceProvider != "" &&
            this.pages.indemnityInsuranceProvider != null &&
            this.pages.indemnityInsuranceNumber != "" &&
            this.pages.indemnityInsuranceNumber != null &&
            this.pages.indemnityInsuranceExpiry != "" &&
            this.pages.indemnityInsuranceExpiry != null
          );
        else
          return (
            !this.errors.any() &&
            this.pages.firstName != "" &&
            this.pages.lastName != "" &&
            this.pages.email != "" &&
            this.pages.password != "" &&
            this.pages.confirmPassword != "" &&
            this.pages.role != "" &&
            this.pages.role != null
          );
      }
      return (
        !this.errors.any() &&
        this.pages.firstName != "" &&
        this.pages.lastName != "" &&
        this.pages.email != "" &&
        this.pages.password != "" &&
        this.pages.confirmPassword != ""
      );
    },
  },
  methods: {
    ...mapActions("admin", [
      "fetchAdminDetails",
      "updateAdminDetails",
      "deleteAdmin",
      "checkEditEmail",
      "resetAdminPassword",
    ]),
    ...mapActions("rolesAndPermissions", ["getAllRoles"]),
    getAdminDetail(id) {
      this.fetchAdminDetails(id).then((res) => {
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages = res.data.data;

        if (!res.data.data.isMedicalPerson) this.pages.isMedicalPerson = "no";
        else this.pages.isMedicalPerson = "yes";

        if (this.pages.status == "Active") {
          this.switch2 = false;
        }
        if (this.pages.status == "Disabled") {
          this.switch2 = true;
        }
        if (this.pages.status == "Pending_Approval") {
          this.switch2 = true;
        }
      });
    },
    async saveDetails() {
      if (
        this.pages.isMedicalPerson === "yes" &&
        !this.pages.ahpraCertificate
      ) {
        this.showAphraError = true;
        return false;
      }

      let checkData = {
        email: this.pages.email,
        id: this.pages._id,
      };
      await this.checkEditEmail(checkData)
        .then((res) => {
          let id = this.$route.params.id;
          // let info = Object.assign({}, this.pages);

          let info = {};
          if (this.pages.userType === "superAdmin") {
            info = {
              userType: this.pages.userType,
              firstName: this.pages.firstName,
              lastName: this.pages.lastName,
              email: this.pages.email,
              status: this.pages.status,
              password: this.pages.password,
              hasApprovedByAdmin: true,
            };
          } else {
            info = {
              userType: this.pages.userType,
              firstName: this.pages.firstName,
              lastName: this.pages.lastName,
              email: this.pages.email,
              status: this.pages.status,
              password: this.pages.password,
              hasApprovedByAdmin: true,
              role: this.pages.role,
              isMedicalPerson:
                this.pages.isMedicalPerson === "yes" ? true : false,
              ahpraCertificate: this.pages.ahpraCertificate,
              ahpraRegistrationNumber: this.pages.ahpraRegistrationNumber,
              ahpraExiryDate: this.pages.ahpraExiryDate,
              insuranceCertificate: this.pages.insuranceCertificate,
              indemnityInsuranceProvider: this.pages.indemnityInsuranceProvider,
              indemnityInsuranceNumber: this.pages.indemnityInsuranceNumber,
              indemnityInsuranceExpiry: this.pages.indemnityInsuranceExpiry,
            };
          }

          if (this.pages.isMedicalPerson === "yes") info.isMedicalPerson = true;
          else info.isMedicalPerson = false;
          // if (!info.emailVerifiedAt) {
          //   info.emailVerifiedAt = new Date().toISOString();
          // }
          this.updateAdminDetails({ id, info }).then((res) => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              // iconPack: 'feather',
              // icon: 'icon-alert-circle',
              color: "success",
            });
          });
        })
        .catch((err) => {
          if (err.status == 422) {
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          } else {
            this.$vs.notify({
              title: "Edit Attempt",
              text: "Server Error",
              // iconPack: 'feather',
              // icon: 'icon-alert-circle',
              color: "warning",
            });
          }
        });
    },
    editStatus() {
      this.statusChangedClick = true;
      if (this.switch2 === true) {
        this.pages.status = "Active";
      }
      if (this.switch2 === false) {
        this.pages.status = "Disabled";
      }

      let id = this.$route.params.id;
      let info = { status: this.pages.status };
      this.updateAdminDetails({ id, info }).then((res) => {
        this.statusChangedClick = false;
        this.$vs.notify({
          title: "Success",
          text: "Status updated successfully.",
          color: "success",
        });
      });
    },
    deleteUser() {
      this.popUpDelete = false;
      let id = this.$route.params.id;
      this.deleteAdmin(id).then((res) => {
        this.$vs.notify({
          subject: "Delete Admin",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({ name: "admin-user" });
      });
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetAdminPassword({
          id: this.pages._id,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "User password reset",
            text: "User's password reset Successfully.",
            color: "success",
          });
          //this.$router.push({name:'admin-user'});
        });
      }
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      document.getElementById("resetPasswordContainer").scrollIntoView();
    },
    getExistingRoles() {
      this.$vs.loading();
      this.getAllRoles()
        .then((result) => {
          this.roles = result.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => console.log(err));
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "aphraUpload") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "aphraUpload") {
            this.showAphraError = false;
            self.pages.ahpraCertificate = res.data.Location;
          }

          if (key === "insuranceUpload") {
            self.pages.insuranceCertificate = res.data.Location;
          }

          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
  created() {
    let pageId = this.$route.params.id;
    this.getAdminDetail(pageId);
    this.getExistingRoles();
  },
};
</script>

<style>
ul {
  list-style: none;
  margin-left: 0px;
}
</style>
