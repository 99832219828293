<template>
  <edit backRoute="SuperAdminClinic" />
</template>

<script>
import edit from "../../components/community-portal/edit.vue";

export default {
  components: {
    edit,
  },
  data: () => ({}),
};
</script>
